@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-size: "Graphik";
}

@layer components {
  .app-layout {
    @apply max-w-[1140px] px-[16px] sm:px-[24px] md:px-[32px] mx-auto;
  }

  .title {
    @apply text-[28px] font-medium text-gray-700;
  }
}

/* swiper bullets */

span.swiper-pagination-bullet {
  background-color: #6741a3;
}
